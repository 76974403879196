import cn from 'classnames';
import styles from './ArrowLinkIcon.module.scss';

type ArrowLinkIconProps = {
  isOpen?: boolean;
  width?: number;
  height?: number;
  staticArrow?: boolean;
};

export function ArrowLinkIcon({
  isOpen,
  width = 24,
  height = 24,
  staticArrow = false,
}: ArrowLinkIconProps) {
  const arrowLinkIconStyles = cn(styles.arrowLinkIcon, {
    [styles.static]: staticArrow,
    [styles.isOpen]: isOpen,
  });

  return (
    <div className={arrowLinkIconStyles}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.svg}
      >
        <path
          d="M12 16L16 12L12 8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12L16 12"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
