import cn from 'classnames';
import styles from './SearchItem.module.scss';
import { SearchResult } from '@/types';
import { useRouter } from 'next/router';
import { parseText } from '@/utils/parseText';

type SearchItemProps = SearchResult & {
  searchValue: string;
  className?: string;
};

export function SearchItem({
  name,
  section,
  url,
  searchValue,
  text,
  className,
}: SearchItemProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const highlightText = (text: string, searchValue: string) => {
    const regex = new RegExp(`(${searchValue})`, 'gi');
    return text.replace(
      regex,
      '<span class="' + styles.highlight + '">$1</span>',
    );
  };

  const router = useRouter();

  const highlightedText = highlightText(parseText(text), searchValue);

  const highlightedTitle = highlightText(parseText(name), searchValue);

  const handleClick = () => {
    router.push(url);
  };

  return (
    <div className={wrapperClassName} onClick={handleClick}>
      <p className={styles.tag}>{parseText(section)}</p>
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: highlightedTitle }}
      />
      <p
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: highlightedText }}
      />
    </div>
  );
}
