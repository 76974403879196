import styles from './ProfileButton.module.scss';
import cn from 'classnames';
import { useRouter } from 'next/router';

type ProfileButtonProps = {
  className?: string;
};

export function ProfileButton({ className }: ProfileButtonProps) {
  const buttonClassName = cn(styles.button, className);
  const router = useRouter();

  return (
    <button className={buttonClassName} onClick={() => router.push('/profile')}>
      <>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.profileSvg}
        >
          <circle
            cx="7.71905"
            cy="4.85186"
            r="3.18535"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66651 12.4672C2.66566 12.2433 2.71574 12.0221 2.81297 11.8204C3.11808 11.2102 3.97849 10.8867 4.69245 10.7403C5.20736 10.6304 5.72937 10.557 6.25462 10.5206C7.22706 10.4352 8.20513 10.4352 9.17757 10.5206C9.70277 10.5574 10.2248 10.6308 10.7397 10.7403C11.4537 10.8867 12.3141 11.1796 12.6192 11.8204C12.8148 12.2316 12.8148 12.709 12.6192 13.1202C12.3141 13.7609 11.4537 14.0538 10.7397 14.1941C10.2254 14.3086 9.70323 14.384 9.17757 14.4199C8.38608 14.487 7.59089 14.4993 6.79771 14.4565C6.61465 14.4565 6.43768 14.4565 6.25462 14.4199C5.73092 14.3845 5.21072 14.309 4.69855 14.1941C3.97849 14.0538 3.12418 13.7609 2.81297 13.1202C2.71624 12.9161 2.66621 12.693 2.66651 12.4672Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    </button>
  );
}
