import styles from './Header.module.scss';
import { NavDropdown } from '@/layout/Header/NavDropdown';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { NavbarButton } from './NavbarButton';
import { BuyTicket } from './BuyTicket';
import { TABLET_RESOLUTION, darkHeaderPages } from '@/constants/constants';
import { BuyButtonLink, MenuItem } from '@/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchActive, selectShowSidebar, setShowSidebar } from '@/store';
import { Search } from '@/components/Search';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { ProfileButton } from './ProfileButton';
import { RWebShare } from 'react-web-share';
import Link from 'next/link';
import { SearchMobile, SearchVariant } from '@/components/SearchMobile';
import { useEffect, useState } from 'react';
import { BurgerButton } from './BurgerButton';

type HeaderProps = {
  menu: MenuItem[];
  links: BuyButtonLink[];
};

export function Header({ menu, links }: HeaderProps) {
  const width = useWindowWidth();
  const [headerHidden, setHeaderHidden] = useState(false);
  const [isOpenedOnBurger, setOpenedOnBurger] = useState(false);
  const isMobile = width && width < TABLET_RESOLUTION;

  const router = useRouter();
  const searchActive = useSelector(selectSearchActive);
  const showSidebar = useSelector(selectShowSidebar);
  const dispatch = useDispatch();
  const isDetailedPage =
    Object.keys(router.query).length ||
    darkHeaderPages.includes(router.pathname);
  const isMainPage = router.pathname === '/';

  const actionsClassName = cn(styles.actions, {
    [styles.actionsActive]: searchActive,
  });

  const headerLinksClassName = cn(styles.main, {
    [styles.hidden]: headerHidden,
  });

  const headerClassName = cn(styles.header, {
    [styles.headerHidden]: headerHidden,
    [styles.headerHiddenActive]: searchActive,
  });

  const handleNavbarButtonClick = (link: string) => {
    if (isMainPage && link === '/') return;
    dispatch(setShowSidebar(false));
    router.push(link);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10 && !isOpenedOnBurger) {
        setHeaderHidden(true);
        return;
      }

      setHeaderHidden(false);
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpenedOnBurger]);

  const toggleHeader = () => {
    if (window.scrollY > 10) {
      setHeaderHidden((prev) => !prev);
      setOpenedOnBurger((prev) => !prev);
    }
    return;
  };

  if (isMobile) {
    return (
      <header className={styles.mobile}>
        {isDetailedPage ? (
          <div className={styles.topDetailed}>
            <div className={styles.topDetailedButtons}>
              <div
                className={styles.topDetailedButton}
                onClick={() => router.back()}
              >
                <img
                  src="/svg/arrow-big.svg"
                  alt="back"
                  className={styles.topDetailedIcon}
                />
              </div>
              <div className={styles.logoMobile}>
                <div className={styles.logoMobileWrapper}>
                  <img
                    src="/svg/logo-primary.svg"
                    alt="main"
                    className={styles.logoMobileIcon}
                    onClick={() => {
                      if (isMainPage) return;
                      router.push('/');
                    }}
                  />
                </div>
              </div>
              <div className={styles.topDetailedActions}>
                <RWebShare
                  data={{
                    url: window.location.href,
                  }}
                >
                  <div className={styles.topDetailedButton}>
                    <img
                      src="/svg/share.svg"
                      alt="share"
                      className={styles.topDetailedIcon}
                    />
                  </div>
                </RWebShare>
                <div className={styles.empty}></div>
                <SearchMobile variant={SearchVariant.Dark} />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.topWrapper}>
            <div className={styles.top}>
              <img
                src={'/svg/logo-primary.svg'}
                width={36}
                height={48}
                alt="logo"
                className={styles.logoMobileMain}
                onClick={() => {
                  if (isMainPage) return;
                  router.push('/');
                }}
              />
              <SearchMobile variant={SearchVariant.Light} />
            </div>
          </div>
        )}
        <div className={styles.bottom}>
          <NavbarButton
            onClick={() => handleNavbarButtonClick('/')}
            img={
              router.pathname === '/' ? '/svg/home-dark.svg' : '/svg/home.svg'
            }
            text="Главная"
          />
          <NavbarButton
            onClick={() => handleNavbarButtonClick('/objects')}
            img={
              router.pathname === '/objects'
                ? '/svg/location-dark.svg'
                : '/svg/location.svg'
            }
            text="Объекты"
          />
          <BuyTicket links={links} />
          <NavbarButton
            onClick={() => handleNavbarButtonClick('/profile')}
            img={
              router.pathname === '/profile'
                ? '/svg/profile-dark.svg'
                : '/svg/profile.svg'
            }
            text="Профиль"
          />
          <NavbarButton
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
            img={showSidebar ? '/svg/category-dark.svg' : '/svg/category.svg'}
            text="Больше"
          />
        </div>
      </header>
    );
  }

  return (
    <header className={headerClassName}>
      <div className={headerLinksClassName}>
        {!searchActive && (
          <>
            <Link
              href={'/'}
              className={styles.logoMain}
              style={{ pointerEvents: isMainPage ? 'none' : 'all' }}
            >
              <img
                src={'/svg/logo-primary.svg'}
                width={64}
                height={83}
                alt="logo"
                className={styles.logo}
              />
            </Link>
            <div className={styles.links}>
              {menu.map((item) => (
                <NavDropdown
                  text={item.name}
                  key={item.id}
                  items={item.items}
                  icon={item.icon}
                  link={item.value}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <div className={actionsClassName}>
        {!searchActive && (
          <>
            <ProfileButton />
            <BurgerButton onCLick={toggleHeader} />
          </>
        )}
        <Search />
        <BuyTicket links={links} />
      </div>
    </header>
  );
}
