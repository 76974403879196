import { MetaType } from '@/types';
import { DefaultSeo } from 'next-seo';

const Meta = ({
  title,
  description,
  image,
  canonical,
  h1 = '',
  text = '',
}: MetaType) => {
  const additionalParams = [
    {
      name: 'h1',
      content: h1,
    },
    {
      name: 'text',
      content: text,
    },
  ];

  return (
    <DefaultSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        images: image ? [{ url: image }] : undefined,
        url: canonical,
        type: 'website',
        site_name: 'Остров мечты',
      }}
      canonical={canonical}
      additionalMetaTags={additionalParams}
    />
  );
};

export default Meta;
