import styles from './BurgerButton.module.scss';
import cn from 'classnames';

type BurgerButtonProps = {
  onCLick: () => void;
  className?: string;
};

export function BurgerButton({ onCLick, className }: BurgerButtonProps) {
  const buttonClassName = cn(styles.button, className);

  return (
    <button className={buttonClassName} onClick={onCLick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.burger}
      >
        <path d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7L4 5ZM20 7C20.5523 7 21 6.55229 21 6C21 5.44772 20.5523 5 20 5V7ZM4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19V17ZM20 19C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17V19ZM20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11V13ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13L4 11ZM4 7L20 7V5L4 5L4 7ZM4 19H20V17H4V19ZM20 11L4 11L4 13L20 13V11Z" />
      </svg>
    </button>
  );
}
